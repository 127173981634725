<template>
  <div class="noticias" style="height: calc(100% - 40px)">
      <h4 class="mx-2 text-secondary">Formularios</h4>
      <div class="cartelera formulario" v-if="noticias">
        <router-link :to="'/noticias/mostrar/'+noticia.uuid" v-for="noticia,i in formularios" :key="i+noticia.titulo" :class="'card card-'+noticia.tipo">
          <h4 class="card-title">{{noticia.titulo}}</h4>
          <p>Presione para ver la noticia</p>
        </router-link>
      </div>
  </div>
  </template>
  <script>
import { NoticiasServices } from '../../noticias/services/NoticiasServices'
  export default {
    name: 'FormulariosView',
    data(){
        return {
            noticias: null
        }
    },
    created(){
        NoticiasServices.api.getCartelera()
        .then((response) => {
        this.noticias = response
        })
    },
    computed: {
      formularios: function(){
        if(this.noticias === null) return []
        else{
            const formularios = []
            for(const diaria of this.noticias.diarias){
              if(diaria.tipo === 'formulario'){
                formularios.push(diaria)
              }
            }
            for(const ultima of this.noticias.ultimas){
              if(ultima.tipo === 'formulario'){
                formularios.push(ultima)
              }
            }
            if(this.noticias.formulario){
              formularios.push(...this.noticias.formulario)
            }
            return formularios
        }
      }
    }
  }
  </script>
    <style scoped>
  .noticias .cartelera.formulario{
    display: block;
  }
  .noticias .cartelera.formulario > .card{
    display: block;
  }
  </style>